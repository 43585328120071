import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AccountAddRequest, DomainDetail, AuthenticationResponse, CampaignAddRequest, CampaignDetail, ContactAddRequest, ContactDetail, ContactListAddRequest, ContactListDetail, CreditOrderAddRequest, CreditOrderDetail, MessageAddRequest, MessageDetail, UserDetail, MessageReportPoint, CostReportPoint, CountryCostDetail, UserAddOptions, DomainAddOptions } from './models/api.model';
import { Router } from '@angular/router';


const endpoint: string = "https://api.enviasms.mx";

@Injectable({
  providedIn: 'root'
})
export class MercurioService {

  constructor(private http: HttpClient, private router: Router) { }

  _domain: DomainDetail = {} as DomainDetail;
  get domain(): DomainDetail{
    return JSON.parse(sessionStorage.getItem("Domain") || '{}') as DomainDetail;
  }
  set domain(val: DomainDetail){
    sessionStorage.setItem("Domain", JSON.stringify(val));
  }


  GetToken() : string | undefined {
    return sessionStorage.getItem("Token")?.toString();
  }

  SetToken(token: string) {
    sessionStorage.setItem("Token", token);
  }

  public BasicLogin(username: string, pass: string) : Observable<AuthenticationResponse | null>
  {
    let contentHeaders: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let basicToken: string = btoa(`${btoa(username)}:${btoa(pass)}`);
    return this.http.get<AuthenticationResponse>(`${endpoint}/who/auth/meon/basic?basicToken=${basicToken}`, {observe: 'response', headers: contentHeaders}).pipe(
      tap((res) => {
        if(res.status == 200 && res.body)
        {
          this.SetToken(res.body.token);
        }
      }),
      catchError((err, obs) => this.reportError(err, obs)),
      map(res => res.body)
    );
  }

  public AddAccount(data: AccountAddRequest) : Observable<DomainDetail | null>
  {
    let contentHeaders: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post<DomainDetail>(`${endpoint}/account`, data, {observe: 'response'}).pipe(
      catchError((err, obs) => this.reportError(err, obs)),
      map(res => {
        if(res.status == 200)
          return res.body;
        return null;
      })
    );
  }

  public Get<T>(resource: string) : Observable<T | null>
  {
    let url = endpoint + resource;
    let contentHeaders: HttpHeaders = new HttpHeaders({
      'Content-Type' : 'application/json',
      'Authorization' : `Bearer ${this.GetToken()}`
    });
    return this.http.get<T>(url, {observe : 'response', headers: contentHeaders}).pipe(
      catchError((err, obs) => this.reportError(err, obs)),
      map(res => res.body)
    );
  }

  public Post<T>(resource: string, content: any) : Observable<T | null>
  {
    let url = endpoint + resource;
    let contentHeaders: HttpHeaders = new HttpHeaders({
      'Content-Type' : 'application/json',
      'Authorization' : `Bearer ${this.GetToken()}`
    });
    return this.http.post<T>(url, content, {observe : 'response', headers: contentHeaders}).pipe(
      catchError((err, obs) => this.reportError(err, obs)),
      map(res => res.body)
    ); 
  }

  public Delete(resource: string) : Observable<HttpResponse<Object>>
  {
    let url = endpoint + resource;
    let contentHeaders: HttpHeaders = new HttpHeaders({
      'Content-Type' : 'application/json',
      'Authorization' : `Bearer ${this.GetToken()}`
    });
    return this.http.delete(url, {observe : 'response', headers: contentHeaders}).pipe(
      catchError((err, obs) => this.reportError(err, obs))
    ); 
  }

  public Put<T>(resource: string, content: any) : Observable<T | null>
  {
    let url = endpoint + resource;
    let contentHeaders: HttpHeaders = new HttpHeaders({
      'Content-Type' : 'application/json',
      'Authorization' : `Bearer ${this.GetToken()}`
    });
    return this.http.put<T>(url, content, {observe : 'response', headers: contentHeaders}).pipe(
      catchError((err, obs) => this.reportError(err, obs)),
      map(res => res.body)
    );
  }

  reportError<T>(err: HttpErrorResponse, obs : Observable<T>)
  {
    console.log(err);
    if(err.status == 401)
    {
      this.router.navigate(['/login']);
    }
    if(err.status == 500)
    {
      //TODO: Get Error Code
    }
    else if(err.status > 500)
    {
      //Server error
    }

    return throwError(err);
  }


  // Sign-Up and Domain stuff

  public SignUp(options: UserAddOptions, reCaptchaToken: string)
  {
    return this.Post<{token: string, user: UserDetail}>(`/who/auth/meon/signup?reCaptchaToken=${reCaptchaToken}`, options)
      .pipe(
        tap(x => {
          if(x)
            this.SetToken(x.token);
        })
      );
  }

  public RefreshToken()
  {
    return this.Get<{token:string}>(`/who/auth/token`).pipe(
      tap(x => this.SetToken(x?.token || ''))
    );
  }

  public GetMyDomains()
  {
    return this.Get<DomainDetail[]>('/who/domain');
  }

  public CreateDomain(options: DomainAddOptions)
  {
    return this.Post<DomainDetail>(`/who/domain/`, options);
  }

  public SwitchDomain(domain: string)
  {
    return this.Get<{token:string}>(`/who/domain/${domain}/token`).pipe(
      tap(x => this.SetToken(x?.token || ''))
    );
  }

  // public GetUsers() : Observable<UserDetail[] | null>
  // {
  //   return this.Get<UserDetail[]>('user');
  // }

  public GetCampaign(campaignID: string) : Observable<CampaignDetail | null>
  {
    return this.Get<CampaignDetail>(`/mercurio/campaign/${campaignID}`);
  }
  
  public GetCampaigns(limit: number, offset: number) : Observable<CampaignDetail[] | null>
  {
    return this.Get<CampaignDetail[]>(`/mercurio/campaign?limit=${limit}&offset=${offset}`);
  }

  public AddCampaign(campaign: CampaignAddRequest) : Observable<CampaignDetail | null>
  {
    return this.Post<CampaignDetail>(`/mercurio/campaign`, campaign);
  }

  public DisableCampaign(campaignID : string)
  {
    return this.Delete(`/mercurio/campaign/${campaignID}`);
  }

  public GetContactLists(limit: number, offset: number) : Observable<ContactListDetail[] | null>
  {
    return this.Get<ContactListDetail[]>(`/mercurio/contactlist?limit=${limit}&offset=${offset}`);
  }

  public GetContactList(contactListID : string) : Observable<ContactListDetail | null>
  {
    return this.Get<ContactListDetail>(`/mercurio/contactlist/${contactListID}`);
  }

  public AddContactList(contactList: ContactListAddRequest) : Observable<ContactListDetail | null>
  {
    return this.Post<ContactListDetail>(`/mercurio/contactlist`, contactList);
  }

  public AddContactsToList(contactListID: string, contacts: ContactAddRequest[]) : Observable<ContactListDetail | null>
  {
    return this.Post<ContactListDetail>(`/mercurio/contactlist/${contactListID}/contacts`, contacts);
  }

  public RemoveContactFromList(contactListID: string, number: string)
  {
    return this.Post(`/mercurio/contactlist/${contactListID}/contact/${number}`, null);
  }

  public GetContactsForList(contactListID: string, limit: number, offset: number) : Observable<ContactDetail[] | null>
  {
    return this.Get<ContactDetail[]>(`/mercurio/contactlist/${contactListID}/contacts`);
  }

  public UploadContactListCSV(contactListID: string, file: File) : Observable<boolean>{
    let formData: FormData = new FormData();
    formData.append("file", file);
    let contentHeaders: HttpHeaders = new HttpHeaders({
      'Authorization' : `Bearer ${this.GetToken()}`
    });
    return this.http
      .post(`${endpoint}/mercurio/contactlist/${contactListID}/csv`
        , formData, {observe: 'response', headers: contentHeaders}).pipe(
      catchError((err, obs) => this.reportError(err, obs)),
      map(res => {
        if(res.status == 200)
          return true;
        return false;
      })
    );
  }

  public GetCustomFieldsForList(contactListID: string) : Observable<string[] | null>
  {
    return this.Get<string[]>(`/mercurio/contact-list/${contactListID}/custom-fields`);
  }

  public GetCreditCheckout(amount: number) : Observable<{ url: string } | null>
  {
    return this.Get<{url:string}>(`/mercurio/credit?amount=${amount}`);
  }

  public CreateStripeCheckout(order: CreditOrderAddRequest) : Observable<CreditOrderDetail | null>
  {
    return this.Post<CreditOrderDetail>('/mercurio/credit', order);
  }

  public GetMyDomain() : Observable<DomainDetail | null> {
    return this.Get<DomainDetail>(`/mercurio/domain`).pipe(
      tap(val => {
        let dom = this.domain;
        dom.credit = val?.credit || 0;
        this.domain = dom;
      })
    );
  }

  public AddMyDomain()
  {
    return this.Post<DomainDetail>(`/mercurio/domain`, null);
  }


  public GetMyMessages(limit: number = 50, offset: number = 0) : Observable<MessageDetail[] | null>
  {
    return this.Get<MessageDetail[]>(`/mercurio/message?limit=${limit}&offset=${offset}`);
  }

  public AddMessage(message: MessageAddRequest) : Observable<MessageDetail | null>
  {
    return this.Post<MessageDetail>('/mercurio/message', message);
  }

  public StopMessage(messageID: string)
  {
    return this.Delete(`/mercurio/message/${messageID}`);
  }

  /**Only functional if account set up with own domain core */
  public GetAccountUsers(limit: number = 50, offset: number = 0) : Observable<UserDetail[] | null>
  {
    return this.Get<UserDetail[]>(`/mercurio/user?limit=${limit}&offset=${offset}`);
  }

  // TODO ....
  
  /**Only functional if account set up with own domain core */
  public AddUser(user: UserAddOptions) : Observable<UserDetail | null>
  {
    return this.Post<UserDetail>('/mercurio/user', user);
  }
  
  /**Only functional if account set up with own domain core */
  public GetUser(userID: string) : Observable<UserDetail | null>
  {
    return this.Get<UserDetail>(`/mercurio/user/${userID}`);
  }

  public GetMessageHistoryForNumber(number: string, from: Date, to: Date)
  {
    return this.Get<MessageDetail[]>(`/mercurio/number/${number}?from=${from.toISOString()}&to=${to.toISOString()}`);
  }

  public GetMessageHistoryStats(from: Date, to: Date)
  {
    return this.Get<MessageReportPoint[]>(`/mercurio/message/stats?from=${from.toISOString()}&to=${to.toISOString()}`);
  }

  public GetMessageHistoryCosts(from: Date, to: Date)
  {
    return this.Get<CostReportPoint[]>(`/mercurio/message/costs?from=${from.toISOString()}&to=${to.toISOString()}`);
  }

  public GetMessagesForCampaign(campaignID: string)
  {
    return this.Get<MessageDetail[]>(`/mercurio/campaign/${campaignID}/message`);
  }

  public GetCosts()
  {
    return this.Get<CountryCostDetail[]>(`/mercurio/cost`);
  }

}
